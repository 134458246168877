import { Link, Outlet, useLocation } from "react-router-dom";
import BasicLayout from "../../layout/BasicLayout";
import useCustomLogin from "../../hooks/useCustomLogin";
import OrderHistoryComponent from "../../components/my/OrderHistoryComponent";
import { resetMemberState } from "../../redux/slices/memberSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

/**
 * /my 내의 경로에 대한 레이아웃 역할
 */
const IndexPage = () => {
  const { t, i18n } = useTranslation();

  //   const location = useLocation();
  //   const pageName = location.pathname.substring(
  //     location.pathname.lastIndexOf("/") + 1
  //   );

  const { isLogin, moveToLogin } = useCustomLogin();

  const dispatch = useDispatch();

  if (!isLogin) {
    dispatch(resetMemberState());
    moveToLogin();
  }
  return (
    <BasicLayout pageClassName="history">
      <div className="content history-content">
        <div className="list-st hst-list">
          <ul className="tabs mypage-tabs">
            <li>
              <Link to="../profile">{t("my-tab-profile")}</Link>
            </li>
            <li>
              <Link to="../record">{t("my-tab-game")}</Link>
            </li>
            <li className="on">
              <Link to="../history">{t("my-tab-order")}</Link>
            </li>
          </ul>
          <OrderHistoryComponent />
        </div>
      </div>
    </BasicLayout>
  );
};

export default IndexPage;
