import React, { useEffect, useState } from "react";
import useCustomLogin from "../../hooks/useCustomLogin";
import { getCookie } from "../../util/cookieUtil";
import RankRowComponent from "./RankRowComponent";
import { getRank } from "../../api/gameApi";
import { toast } from "react-toastify";
import { dd } from "framer-motion/client";
import { numberWithCommas } from "../../util/numberUtil";
import { isEmpty } from "../../util/stringUtil";
import { API_SERVER_HOST } from "../../api/apiConfig";
import { Helmet } from "react-helmet";
import Loading from "../../components/common/LoadingComponentMinimal";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const RankComponent = ({ rp }) => {
  const { t, i18n } = useTranslation();

  //console.log("^^RankComponent : ", rp);

  const [serverData, setServerData] = useState([]);

  const [showLoading, setShowLoading] = useState(false); // 로딩 화면 표시 상태

  const { isLogin, loginState } = useCustomLogin();
  // console.log("^^record-isLogin", isLogin, "#", loginState);
  // console.log("^^record-cookie : ", getCookie("member"));

  const memberId = loginState.memberId;

  const host = API_SERVER_HOST;

  useEffect(() => {
    setServerData([]); // 초기화

    let timeoutId;

    // 3초 후 로딩 화면 표시
    timeoutId = setTimeout(() => {
      setShowLoading(true);
    }, 2000);

    getRank({ rp: rp, memberId: memberId })
      .then((response) => {
        if (Array.isArray(response)) {
          //setServerData(response);
          setServerData(response);
        } else {
          console.error("Expected an array but received:", response);
        }
      })
      .catch((err) => {
        toast.error(err, { theme: "dark" });
      })
      .finally(() => {
        clearTimeout(timeoutId); // API 호출 완료 시 타이머 정리
        setShowLoading(false); // 로딩 상태 비활성화
      });

    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearTimeout(timeoutId);
  }, [rp]);

  if (serverData) {
    //console.log("^^serverData: ", serverData);
  }

  if (showLoading) {
    return <Loading />; // 로딩 중일 때 Loading 컴포넌트 표시
  }

  return (
    <>
      <Helmet>
        <title>{t("html-header-rank-title")}</title>
        <meta
          name="description"
          content={t("html-header-rank-meta-description")}
        />
        <meta property="og:title" content={t("html-header-rank-meta-title")} />
        <meta
          property="og:description"
          content={t("html-header-rank-meta-description")}
        />
      </Helmet>

      <div className="tab-cont">
        <div className="list">
          {serverData.length > 0 ? (
            serverData
              .filter((rank) => rank.rankType === "A")
              .map((rank) => {
                return (
                  <RankRowComponent
                    key={rp + "-" + rank.myGameIdx}
                    data={rank}
                  />
                );
              })
          ) : (
            <></>
          )}
        </div>

        {serverData.length > 0 ? (
          serverData
            .filter((rank) => rank.rankType === "B" && rank.rank > 10)
            .map((rank) => {
              const photo = isEmpty(rank.photo)
                ? "/img/00-icon-profile.png"
                : host + "/api/file/member/" + rank.photo;
              return (
                <>
                  <div className="brief">
                    <em></em>
                    <em></em>
                    <em></em>
                  </div>
                  <div className="my-rk">
                    <div className="rk-box">
                      <div className="left">
                        <div className="spot">
                          <p className="rank">{rank.rank}</p>
                          <em className="rk-sam"></em>
                        </div>
                      </div>

                      <div className="center">
                        <div className="photo">
                          <img src={photo} alt="" />
                        </div>
                        <div className="info">
                          <div className="nick">
                            <p>{rank.nickname}</p>
                          </div>

                          <div className="lv-progress">
                            <div className="lv">
                              <em className="icon"></em>
                              <div className="txt">
                                {rank.periodName} {rank.gameCount}
                              </div>
                            </div>

                            <div className="progress">
                              <div className="percent w98"></div>
                              <div className="txt">{rank.progress}%</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="right">
                        <div className="point">
                          <div className="icon"></div>
                          <p className="txt">
                            {numberWithCommas(rank.totalPoint)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default RankComponent;
