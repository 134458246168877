import i18n from "../i18n"; // i18n 설정 파일 import

//기본 상태
export const getBasicState = (code) => {
  return i18n.t(`BASIC_${code}`) || i18n.t("Invalid code");
};

// 주문상태 코드
export const getOrderState = (code) => {
  return i18n.t(`ORDER_${code}`) || i18n.t("Invalid code");
};

// 결제상태 코드
export const getPaymentState = (code) => {
  return i18n.t(`PAYMENT_${code}`) || i18n.t("Invalid code");
};

// 결제방법 코드
export const getPayType = (code) => {
  return i18n.t(`PAY_${code}`) || i18n.t("Invalid code");
};

// 언어 코드
export const getLangCode = (code) => {
  return i18n.t(`LANG_${code}`) || i18n.t("Invalid code");
};
