import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { param } from "framer-motion/client";
import { postSetGame } from "../../api/gameApi";

/**
 * 게임설정 Thunk 생성
 * !! 비직렬화 가능한 값들을 필터링하고 필요한 데이터만 액션으로 전달
 */
export const postSetGameAsync = createAsyncThunk(
  "postSetGameAsync",
  async (params) => {
    const res = await postSetGame(params);
    return {
      data: res.data, // 직렬화 가능한 값만 반환
      status: res.status,
    };
  }
);

const initState = {
  memberId: "",
  isAutoRun: false,
  isSound: false,
  lang: "ko",
  heart: 10,
  gameSpeed: 150, // 게임 타이핑 속도(1:200, 2:150, 3:100) 보통:120
  result: false,
};

const gameSetSlice = createSlice({
  name: "gameSetSlice",
  initialState: initState,
  reducers: {
    // 상태를 완전히 초기화한 후에, action.payload로 전달된 값들을 추가로 설정
    setGame: (state, action) => {
      return {
        ...initState, // 상태를 initState로 초기화
        ...action.payload, // 액션으로 전달된 payload 객체가 초기 상태 위에 덮어씀
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSetGameAsync.fulfilled, (state, action) => {
        console.log(
          "^^postSetGameAsync fullfilled : ",
          state,
          "#",
          action.payload.data
        );
        return action.payload.data; // 응답 데이터만 상태에 저장
      })
      .addCase(postSetGameAsync.rejected, (state, action) => {
        console.log("^^postSetGameAsync rejected : ", state, action);
      });
  },
});

// 액션과 리듀서 export
export const { setGame } = gameSetSlice.actions;
export default gameSetSlice.reducer;
