import React, { useEffect, useState } from "react";
import useCustomNative from "../../hooks/useCustomNative";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const DefaultComponent = () => {
  const { t, i18n } = useTranslation();

  const { nativeGame } = useCustomNative();

  const [isBgm, setIsBgm] = useState(false);

  const handleBgmChange = (e) => {
    setIsBgm(e.target.checked);
  };

  useEffect(() => {
    nativeGame({ type: "sound_typing", value1: isBgm ? "Y" : "N", value2: "" }); // 사운드 off(RN)
  }, [isBgm]);

  return (
    <>
      <Helmet>
        <title>{t("html-header-setting-title")}</title>
        <meta
          name="description"
          content={t("html-header-setting-meta-description")}
        />
        <meta
          property="og:title"
          content={t("html-header-setting-meta-title")}
        />
        <meta
          property="og:description"
          content={t("html-header-setting-meta-description")}
        />
      </Helmet>

      <div className="set-info roll">
        <div className="inner">
          <div className="set">
            <div className="op sound">
              <h3>{t("setting-sound")}</h3>
              <input
                type="checkbox"
                name="bgm"
                value="true"
                id="s-onoff"
                checked={isBgm}
                onChange={handleBgmChange}
              />
              <label htmlFor="s-onoff">
                <div className="icon-box"></div>
              </label>
              <label htmlFor="s-onoff">
                <div className="on-off">
                  <em></em>
                </div>
              </label>
            </div>
            <div className="op voice">
              <h3>{t("setting-voice")}</h3>
              <input type="checkbox" name="" id="v-onoff" />
              <label htmlFor="v-onoff">
                <div className="icon-box"></div>
              </label>
              <label htmlFor="v-onoff">
                <div className="on-off">
                  <em></em>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultComponent;
