import jwtAxios from "../util/jwtUtil";
import { API_SERVER_HOST } from "./apiConfig";

const host = `${API_SERVER_HOST}/api/cart`;

// 장바구니 아이템 모두 가져오기
// !async 키워드는 함수가 비동기적으로 동작한다는 것을 나타내며,
//    함수 내에서 await 키워드를 사용하여 비동기 작업을 처리할 수 있도록 해줌.
export const getCartItems = async () => {
  // !await 키워드는 해당 비동기 작업이 완료되기를 기다리고, 그 결과를 받아옵니다.
  // !await : 기다려~(비동기 작업이 끝날 때 까지 기다려...)
  // 인증정보는 자동으로 설정이 된다.  (jwtAxios 에서 이미 구현했다)

  console.log("^^ ---------- getCartItems");
  const res = await jwtAxios.get(`${host}/items`);

  // 반드시 data 를 반환시켜야 한다.  ==> res 에는 config, headers, request, status, data 등 여러 값들을 반환한다.
  // data 에 실제 장바구니 데이터인 JSON 데이터가 배열 형태로 있다.
  return res.data;
};

// 장바구니 수정 (상품 수량 수정, 아이템이 없으면 등록)
export const postChangeCart = async (cartItem) => {
  // post 로 api 를 호출하고 여기 res 변수에 담기(응답) 전에 인터셉터에 의해
  //  beforeRes 함수가 호출. (accessToken 만료시 refresh 토큰을 이용해 재발급 후 다시 api 호출함)
  // 파라미터: 두번째 매개변수인 파라미터는 객체를 던지만 api 에서 RequestBody 로 받고, 값으로 던지면 RequestParam 으로 받는다.

  //console.log("^^장바구니수정 - 2. postChangeCart ");
  const res = await jwtAxios.post(`${host}/change`, cartItem, {
    withCredentials: true, // 쿠키 자동 전송 활성화
  });
  return res.data;

  // API 에서 오류가 발생하면 예외처리한다.
  // try {
  //   const res = await jwtAxios.post(`${host}/change`, cartItem);
  //   return res.data;
  // } catch (error) {
  //   // 에러를 그대로 던짐 (jwtUtils 에서 받은 에러 그대로 던진다)
  //   console.log("^^ error : " , error)
  //   throw error;    // ex) 'ERROR_ACCESSDENIED'
  // }
};
