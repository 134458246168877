import React from "react";
import BasicLayout from "../../layout/BasicLayout";
import DefaultComponent from "../../components/setting/DefaultComponent";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const DefaultPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <BasicLayout pageClassName="setting bg-map">
      <div className="content setting-content">
        <h2 className="tit">{t("setting-head-tit")}</h2>

        <DefaultComponent />
      </div>
    </BasicLayout>
  );
};

export default DefaultPage;
