import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../../util/numberUtil";
import { API_SERVER_HOST } from "../../api/apiConfig";

const RankRowComponent = React.memo(({ data }) => {
  //console.log("^^data : ", data);

  const [photo, setPhoto] = useState("/img/00-icon-profile.png");
  const [cssBox, setCssBox] = useState("");

  const host = API_SERVER_HOST;

  useEffect(() => {
    const memPhoto = host + "/api/file/member/" + data.photo;

    const img = new Image();
    img.src = memPhoto;

    img.onload = () => {
      setPhoto(memPhoto);
    };
    img.onerror = () => {
      //console.log("^^photo no");
    };

    if (data.rank < 4) {
      setCssBox("rk-box rk rk" + data.rank);
    } else {
      setCssBox("rk-box");
    }
  }, []);

  return (
    <div className={cssBox}>
      <div className="left">
        <div className="spot">
          <p className="rank">{data.rank}</p>
          <em className="rk-up">3</em>
        </div>
      </div>

      <div className="center">
        <div className="photo">
          <img src={photo} alt="" />
        </div>
        <div className="info">
          <div className="nick">
            <p>{data.nickname}</p>
          </div>

          <div className="lv-progress">
            <div className="lv">
              <em className="icon"></em>
              <div className="txt">
                {data.periodName.split(" ")[0]} {data.gameCount}
              </div>
            </div>

            <div className="progress">
              <div className={`percent w${data.progress}`}></div>
              <div className="txt">{data.progress}%</div>
            </div>
          </div>
        </div>
      </div>

      <div className="right">
        <div className="point">
          <div className="icon"></div>
          <p className="txt">{numberWithCommas(data.totalPoint)}</p>
        </div>
      </div>
    </div>
  );
});

export default RankRowComponent;
