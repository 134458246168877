import React from "react";
import {
  getOrderState,
  getPaymentState,
  getPayType,
} from "../../util/codeUtil";
import { numberWithCommas } from "../../util/numberUtil";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const OrderHistoryRowComponent = ({ data }) => {
  const { t, i18n } = useTranslation();

  //console.log("^^data : ", data);
  return (
    <>
      <ul className="tr">
        <li className="td no" data-txt="no">
          <p>{data.orderNo}</p>
        </li>
        <li className="td date" data-txt={t("my-purchase-head-dt")}>
          <p>{data.createTime.slice(0, 10)}</p>
        </li>
        <li className="td product" data-txt={t("my-purchase-head-name")}>
          <p>{data.orderName}</p>
        </li>
        <li className="td state" data-txt={t("my-purchase-head-state")}>
          <p>{getOrderState(data.orderState)}</p>
        </li>
        <li className="td payment" data-txt={t("my-purchase-head-detail")}>
          <p>
            {t("my-purchase-head-price")} :{" "}
            {numberWithCommas(numberWithCommas(data.totalAmount))}
            <br />
            {t("my-purchase-head-state")} : {getPaymentState(data.paymentState)}
            <br />
            {getPayType(data.payType)} (00000000000)
          </p>
        </li>
      </ul>
    </>
  );
};

export default OrderHistoryRowComponent;
