import { useCallback, useEffect, useRef, useState } from "react";
import useCustomGame from "./useCustomGame";
import useCustomGameControl from "./useCustomGameControl";
import { validateData } from "../util/validate";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { truncateDecimal } from "../util/numberUtil";
import { setGameControl } from "../redux/slices/gameControlSlice";
import { setIsTypeItFrozen } from "../global/globalState";
import { postGameSaveAsync, updateGameInfo } from "../redux/slices/gameSlice";
import { toast } from "react-toastify";
import useCustomNative from "./useCustomNative";
import { useTyping } from "../components/game/TypingContext";
/**
 * 게임 컨텐츠 제어 훅
 * 언더바(_)가 붙은 변수는 답안(ChoiceComponent)에서 선택할 경우 중점적으로 사용됨
 * !!! 이 훅은 TextQuizComponent, ChoiceComponent 에서 사용되는데, 각각 참조되는 값이 다르다.(각각 독립적임) 주의 필요!!!
 * 특히 ChoiceComponent 는 오답시 optionCount 상태가 업데이트 되지 않기 때문에 다음 문제까지 초기값이 계속 유지가 된다.
 */
const useCustomGameManage = (typeItRef) => {
  //console.log("^^useCustomGameManage : ", typeItRef);

  //const { gameControlState, updateGameControlState } = useCustomGameControl();

  const gameState = useSelector((state) => state.gameSlice, shallowEqual);

  const gameControlState = useSelector(
    (state) => state.gameControlSlice,
    shallowEqual
  );

  const isTypingRef = useTyping(); // Context에서 isTypingRef 가져오기

  const dispatch = useDispatch();

  const { nativeGame } = useCustomNative();

  let _isGameOpen = true; // 게임 종료 상태 여부
  let _isCorrect = null;

  let _isChoice = false;
  let _isModal = false;
  let _totalGameCount = gameControlState.totalGameCount;
  let _gameCount = gameControlState.gameCount;

  let _optionCount = gameControlState.optionCount; // 선택 선택지
  let _prevOptionCount = gameControlState.prevOptionCount; // 이전 선택지

  let _heart = gameControlState.heart;
  let _hodu = gameControlState.hodu;

  let _totalPoint = gameControlState.totalPoint;
  let _progress = 0;

  let _pridx = gameControlState.pridx;
  let _kdidx = gameControlState.kdidx;

  let _gcIdx = gameControlState.gcidx;
  let _optIdx = gameControlState.optIdx;
  let _gcPoint = 0;
  let _gcAtStart = gameControlState.gcAtStart; // 초기 한번 설정 (TextQuizComponent > initOption 에서 설정)
  let _gcAtEnd = 0; // 마지막에 설정(_isSave=true)
  let _answer = "";

  let _optCount = 0;

  let _isSave = false;

  //console.log("^^useCustomGameManage-gcAtStart:", gameControlState.gcAtStart);

  const optionCountRef = useRef(gameControlState.optionCount);

  // 게임 컨트롤 상태 값이 변할 때마다 ref 값을 업데이트
  // 자동 답안 제출하는 경우 선택 제출하는 객체가 다르다.
  // 그래서 자동 답안 제출할 때 아래 useEfeect 가 동작하여 optionCountRef 에 최신 상태값을 할당한다.
  // 답안 선택시 영향 없음.
  useEffect(() => {
    optionCountRef.current = gameControlState.optionCount;

    // console.log(
    //   "^^useEffect-gameControlState.optionCount : ",
    //   gameControlState.optionCount,
    //   "#",
    //   optionCountRef.current,
    //   "#",
    //   gameControlState.gcAtStart
    // );
  }, [gameControlState.optionCount]);

  /*
   * // 게임 저장
   */
  const handleGameCallback = async (param) => {
    // 초기값 : 저장하지 않음
    _isSave = false; // 매 게임 저장 여부 (하트소진, 정답, 마지막 선택지)

    console.log("^^handleGameCallback : ", param);

    // console.log(
    //   "^^useCustomGameManage-handleGameCallback-gcAtStart:",
    //   gameControlState.gcAtStart
    // );

    // console.log(
    //   "^^init let : ",
    //   _gameCount,
    //   "/",
    //   _optionCount,
    //   "/",
    //   _heart,
    //   "/",
    //   _gcIdx,
    //   "/",
    //   _optIdx,
    //   "/",
    //   _optCount,
    //   "/",
    //   _totalPoint
    // );

    const { value, ca, gameType, gcIdx, optIdx, optCount, totalCount } = param;

    const resultOX = validateData(value, ca); // 정답 여부

    // console.log(
    //   "^^resultOX : ",
    //   resultOX,
    //   "#gameType:",
    //   gameType,
    //   "#gcIdx:",
    //   gcIdx,
    //   "#optIdx:",
    //   optIdx,
    //   "#optCount:",
    //   optCount
    // );

    _isCorrect = resultOX;
    _isChoice = value === "" ? false : true;

    //console.log("^^useCustomGameManage-isTypingRef : ", isTypingRef.current);

    // 답안 선택 경우 현재 카운터는 참조 카운트 적용(최신 카운터 적용)
    // 최신카운터: 마지막 갱신된 카운터 => 마지막 답안 자동 제출하고 갱신된 카운터 (optionCountRef 서로 다른 컴포넌트와 공유됨)
    if (_isChoice) {
      _optionCount = optionCountRef.current; // optionCounter 공유
      _prevOptionCount = _optionCount; // 이전 선택지 번호, 선택시 현재 카운터 저장 (답안 선택시 하트 차감하지 않는다.)
    } else {
      _optionCount = gameControlState.optionCount;
    }

    _gcAtStart = gameControlState.gcAtStart;

    _answer = value;
    _progress = truncateDecimal(
      ((gameControlState.gameCount + 1) / totalCount) * 100,
      1
    );

    _gcAtEnd = Date.now(); // 매 게임 종료 시간

    _isGameOpen = _gameCount === _totalGameCount - 1 ? false : true; // 게임 종료 설정(마지막 게임이면 게임 종료)

    // console.log(
    //   "^^_isChoice:",
    //   _isChoice,
    //   "#",
    //   _isGameOpen,
    //   "#",
    //   _totalGameCount,
    //   "#",
    //   _gameCount,
    //   "#",
    //   _heart,
    //   "#",
    //   _optionCount,
    //   "#",
    //   _gcAtStart
    // );

    // 정답일경우 (게임,선택지 카운터 증가는 모달에서한다.)
    if (resultOX) {
      const _addPoint = isTypingRef.current ? 1 : 0;
      _gcPoint = 100 - (_optionCount + 0) * 20 + _addPoint;
      _totalPoint = gameControlState.totalPoint + _gcPoint;

      // console.log(
      //   "^^========== ",
      //   _optionCount,
      //   "#",
      //   _gcPoint,
      //   "#",
      //   _totalPoint,
      //   "#",
      //   _gcAtStart,
      //   "#",
      //   _gcAtEnd
      // );

      // 타이핑 중지, 상태변경(정답모달오픈), 멈춤
      if (typeItRef.current) {
        //console.log("^^OK-Typping STOP ", typeItRef.current);

        // !!! 모달 창이 오픈될때는 무조건 TypeIt 는 중지 상태여야 한다.
        // !!! (pause 상태에서 freeze() 적용안됨 : 선택지 카운트 진행하는 동안 정지 기능 작동 안됨)
        // !!! freeze 상태 변경(pause 상태에서 freeze() 적용안됨, 전역 변수로 한번 더 처리함)
        setIsTypeItFrozen(true); // 1
        typeItRef.current.freeze(); // 2
      }
      _isModal = true;

      _isSave = true; // 매 게임 저장 여부 (하트소진, 정답, 마지막 선택지)

      dispatch(
        setGameControl({
          isGameOpen: _isGameOpen,
          isChoice: _isChoice, // 선택유무
          isCorrect: _isCorrect,
          isModal: _isModal, // 모달 오픈
          progress: _progress,
          totalPoint: _totalPoint,
          gcPoint: _gcPoint,
          gcAtStart: _gcAtStart,
          gcAtEnd: _gcAtEnd,
          answer: _answer,
          gameType: gameType,
        })
      );
    } else {
      // 오답 (하트 차감 조건 : 마지막 선택지 또는 하트 모두 소진) , 보너스게임도 모두 차감
      if (
        _isChoice &&
        _prevOptionCount === _optionCount &&
        (gameType === "KING" || gameType === "BONUS")
      ) {
        // 선택 + 이전 카운트와 같다.(선택시 무조건 같다.)
        _heart = _heart - 1;

        // console.log(
        //   "~~~~aa-",
        //   _isChoice,
        //   "&&",
        //   _prevOptionCount,
        //   "!==",
        //   _optionCount
        // );
      } else if (
        !_isChoice &&
        _prevOptionCount !== _optionCount &&
        (gameType === "KING" || gameType === "BONUS")
      ) {
        // 한번도 선택하지 않았기 때문에 차감
        _heart = _heart - 1;

        // console.log(
        //   "~~~~bb-",
        //   _isChoice,
        //   "&&",
        //   _prevOptionCount,
        //   "!==",
        //   _optionCount
        // );
      }
      // else : 선택했고 이전 카운트와 같다 (한번 이상 선택했기 때문에 자동 패스시 차감하지 않는다.)

      // console.log(
      //   "^^HEART - ",
      //   gameControlState.heart,
      //   "#",
      //   _heart,
      //   "#",
      //   _optCount,
      //   "#",
      //   optCount
      // );

      if (_heart <= 0) {
        // console.log("^^마지막 하트-11");

        if (typeItRef.current) {
          //console.log("^^오답-하트소진-타이핑중지 ", typeItRef.current);
          setIsTypeItFrozen(true);
          typeItRef.current.freeze();
        }

        // 사운드 정지
        //nativeGame({ type: "sound_typing", value1: "N", value2: "" }); // 사운드 off(RN)

        _isModal = true; // 모달 오픈
        _isGameOpen = false; // 게임종료

        _isSave = true; // 매 게임 저장 여부 (하트소진, 정답, 마지막 선택지)

        dispatch(
          setGameControl({
            heart: _heart,
            isChoice: _isChoice, // 선택유무
            isCorrect: _isCorrect,
            isModal: _isModal, // 모달 오픈
            gcAtStart: _gcAtStart,
            gcAtEnd: _gcAtEnd,
            answer: _answer,
            gcIdx: gcIdx,
            optIdx: optIdx,
            isGameOpen: _isGameOpen,
            gameType: gameType,
          })
        );
      } else if (_heart > 0 && _optionCount === optCount - 1) {
        //console.log("^^마지막 선택지-22"); // 마지막 선택지, 다음이 신규 문제
        // !! 오답인데 마지막 선택지리는 뜻이다.
        // !! 이 마지막 선택지에서 답안을 선택했으면 게임종료가 아니기 때문에 save 하지 않는다. (계속 하트만 차감)
        // !! 결국 마지막 선택지도 자동 패스될 때만 타이핑을 중지하고 데이터를 저장한다.
        // 마지막 선택지라도 답안 선택하지 않으면 하트,정답여부,제출답,게임키만 저장
        // ------------------------------------------------------------------------
        // !! 보너스 게임은 한 번만 선택한다.
        //    보너스 게임은 한 문제다.
        //    한 번 선택(정답 or 오답), 포인트 차감없이 오답시 바로 끝낸다.
        if (!_isChoice || gameType === "BONUS") {
          //console.log("^^마지막 선택지-33 : ", gameType); // 마지막 선택지, 다음이 신규 문제
          setIsTypeItFrozen(true);
          typeItRef.current.freeze();

          _isSave = true; // 매 게임 저장 여부 (하트소진, 정답, 마지막 선택지)
        }

        // 사운드 정지
        //nativeGame({ type: "sound_typing", value1: "N", value2: "" }); // 사운드 off(RN)

        dispatch(
          setGameControl({
            heart: _heart,
            isChoice: _isChoice, // 선택유무
            isCorrect: _isCorrect,
            gcAtStart: _gcAtStart,
            gcAtEnd: _gcAtEnd,
            answer: _answer,
            gcIdx: gcIdx,
            optIdx: optIdx,
            ...((_isChoice || gameType === "BONUS") && {
              prevOptionCount: _prevOptionCount,
            }), // 이전 카운터 저장(선택시 or 보너스게임)
            ...((!_isChoice || gameType === "BONUS") && {
              isGameOpen: _isGameOpen,
            }),
            ...((!_isChoice || gameType === "BONUS") && { isModal: true }), // 모달 오픈
            ...((!_isChoice || gameType === "BONUS") && {
              progress: _progress,
            }),
            gameType: gameType,
          })
        );
      } else if (_heart > 0 && _optionCount < optCount - 1) {
        // 마지막 선택지가 아니라면 (다음이 새 문제가 아님, 모달창 패스)
        //console.log("^^마지막 선택지가 아님-33-저장필요없음");

        dispatch(
          setGameControl({
            heart: _heart,
            isChoice: _isChoice, // 선택유무
            isCorrect: _isCorrect,
            answer: _answer,
            gcIdx: gcIdx,
            optIdx: optIdx,
            ...(_isChoice && { prevOptionCount: _prevOptionCount }), // 이전 카운터 저장(선택시)
            ...(!_isChoice && { optionCount: _optionCount + 1 }), // 답안을 선택하는 경우 오답일 때는 다음 선택지로 넘어가지 않는다. 하트 소진되면 여기까지 오지 않기때문에 계속 차감시키면 됨
            ...(!_isChoice && { gcPoint: 100 - (_optionCount + 1) * 20 }), // 답안을 선택하는 경우 오답일 때는 포인트를 차감하지 않는다. (자동 패스될 때 차감)
            gameType: gameType,
          })
        );
      }
    }

    // console.log(
    //   "^^_isSave : ",
    //   _isSave,
    //   "-",
    //   gameControlState.gcAtStart,
    //   "-",
    //   _gcAtStart,
    //   "-",
    //   _gcAtEnd
    // );

    if (_isSave) {
      const gameParam = {
        isGameOpen: _isGameOpen,
        memberId: gameControlState.memberId,
        myGameIdx: gameControlState.myGameIdx,
        heart: _heart,
        hodu: _hodu,
        pridx: _pridx,
        kdidx: _kdidx,
        totalPoint: _totalPoint,
        progress: _progress,
        gameCount: _gameCount,
        gameType: gameType,
        gcIdxs: [...gameState.gcIdxs, gcIdx],
        optIdxs: [...gameState.optIdxs, optIdx], // 게임의 마지막 선택지키
        gcPoint: [...gameState.gcPoint, _gcPoint],
        gcAtStart: [...gameState.gcAtStart, _gcAtStart],
        gcAtEnd: [...gameState.gcAtEnd, _gcAtEnd],
        answer: [...gameState.answer, _answer],
        isCorrect: [...gameState.isCorrect, _isCorrect],
      };

      dispatch(updateGameInfo(gameParam));

      try {
        const resultAction = await dispatch(postGameSaveAsync(gameParam)); // 서버 저장

        //console.log("^^postGameSaveAsync-result:", resultAction.payload);

        if (resultAction.payload?.result === true) {
          // console.log(
          //   "^^postGameSaveAsync-success:",
          //   resultAction.payload?.result
          // );
        } else {
          toast.error(resultAction.payload, { theme: "dark" });
        }
      } catch (error) {
        console.log("^^Error updating game:", error);
        toast.error(error, { theme: "dark" });
      }
    }
  };

  return { handleGameCallback };
};

export default useCustomGameManage;
