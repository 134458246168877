import React, { useState } from "react";
import useCustomLogin from "../../hooks/useCustomLogin";
import { Link } from "react-router-dom";
import { getKakaoLoginLink } from "../../api/kakaoApi";
import { getGoogleLoginLink } from "../../api/googleApi";
import { getNaverLoginLink } from "../../api/naverApi";
import { Helmet } from "react-helmet";
import { getAppleLoginLink } from "../../api/appleApi";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const initState = {
  memberId: "",
  passwd: "",
};

const LoginComponent = () => {
  const { t, i18n } = useTranslation();

  const [loginParam, setLoginParam] = useState(initState);
  //const dispatch = useDispatch();

  const { doLogin, moveToPath } = useCustomLogin();

  // // Facebook 일단 중지
  // // Facebook SDK 초기화
  // useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: "418023248058152", // Facebook Developer에서 발급받은 앱 ID
  //       cookie: true,
  //       xfbml: true,
  //       version: "v20.0", // Facebook Graph API 버전
  //     });

  //     // 로그인 상태를 체크합니다
  //     window.FB.getLoginStatus(function (response) {
  //       console.log("Facebook login status:", response);
  //     });
  //   };

  //   // Facebook SDK를 비동기적으로 로드합니다
  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // }, []);

  // // Facebook 로그인 함수
  // const handleFacebookLogin = () => {
  //   window.FB.login(
  //     function (response) {
  //       if (response.authResponse) {
  //         const accessToken = response.authResponse.accessToken;
  //         console.log("Facebook Access Token:", accessToken);

  //         fetch("http://localhost:8080/auth/facebook", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             accessToken: accessToken,
  //             userID: response.authResponse.userID,
  //           }),
  //         })
  //           .then((response) => response.json())
  //           .then((data) => {
  //             console.log("Server response:", data);
  //           })
  //           .catch((error) => {
  //             console.error("Error during Facebook authentication:", error);
  //           });
  //       } else {
  //         console.log("User cancelled login or did not fully authorize.");
  //       }
  //     },
  //     { scope: "public_profile,email" }
  //   );
  // };

  const kakaoLink = getKakaoLoginLink();
  const googleLink = getGoogleLoginLink();
  const naverLink = getNaverLoginLink();
  const appleLink = getAppleLoginLink();

  return (
    <>
      <Helmet>
        <title>{t("html-header-login-title")}</title>
        <meta
          name="description"
          content={t("html-header-login-meta-description")}
        />
        <meta property="og:title" content={t("html-header-login-meta-title")} />
        <meta
          property="og:description"
          content={t("html-header-login-meta-description")}
        />
      </Helmet>
      <div className="sns-login">
        <div className="sns-btn">
          <Link to={kakaoLink} className="kakao">
            <img src="../img/01-kakao.svg" alt={t("login-btn-kakao")} />
            {t("login-btn-kakao")} {t("login")}
          </Link>

          <Link to={naverLink} className="naver">
            <img src="../img/01-naver.svg" alt={t("login-btn-naver")} />
            {t("login-btn-naver")} {t("login")}
          </Link>

          <Link to={googleLink} className="google">
            <img src="../img/01-google.svg" alt={t("login-btn-google")} />
            {t("login-btn-google")} {t("login")}
          </Link>

          <Link to={appleLink} className="apple">
            <img src="../img/01-apple.svg" alt={t("login-btn-apple")} />
            {t("login-btn-apple")} {t("login")}
          </Link>

          {/* <a href="/member/login" className="facebook">
          <img src="../img/01-facebook.svg" alt="페이스북" />
          페이스북으로 로그인
        </a> */}

          {/* <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            handleFacebookLogin();
          }}
          className="facebook"
        >
          <img src="../img/01-facebook.svg" alt="페이스북" />
          페이스북으로 로그인
        </a> */}
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
