import React, { useEffect, useState } from "react";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useSelector } from "react-redux";
import withLoginCheck from "../../hoc/withLoginCheck";
import { getRecord } from "../../api/myApi";
import RecordRowComponent from "./RecordRowComponent";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const RecordComponent = () => {
  const { t, i18n } = useTranslation();

  const [serverData, setServerData] = useState([]);

  const { isLogin, loginState, moveToLogin } = useCustomLogin();

  //console.log("^^record-isLogin", isLogin, "#", loginState);

  if (!isLogin) {
    moveToLogin();
  }

  const memberId = loginState.memberId;

  useEffect(() => {
    getRecord({ memberId: memberId, limit: 100 })
      .then((response) => {
        if (Array.isArray(response)) {
          setServerData(response);
        } else {
          console.error("Expected an array but received:", response);
        }
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("html-header-record-title")}</title>
        <meta name="description" content={t("html-header-record-meta-title")} />
        <meta
          property="og:title"
          content={t("html-header-record-meta-description")}
        />
        <meta
          property="og:description"
          content={t("html-header-record-meta-title")}
        />
      </Helmet>

      <div className="tab-cont">
        <div className="table">
          <ul className="th">
            <li className="time">
              <em></em>
              <p>Time</p>
            </li>
            <li className="lv">
              <em></em>
              <p>LV</p>
            </li>
            <li className="point">
              <em></em>
              <p>Point</p>
            </li>
            <li className="coin">
              <em></em>
              <p>{t("my-game-head-hodu")}</p>
            </li>
          </ul>
          <div className="list">
            {serverData.length > 0 ? (
              serverData.map((record) => {
                return (
                  <RecordRowComponent key={record.myGameIdx} data={record} />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withLoginCheck(RecordComponent);
