import React, { useEffect, useState } from "react";
import BoardRowComponent from "./BoardRowComponent";
import { getList } from "../../api/boardApi";
import useCustomMove from "../../hooks/useCustomMove";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const initState = {
  dtoList: [],
  pageNumList: [],
  pageRequestDTO: null,
  prev: false,
  next: false,
  totalCount: 0,
  prevPage: 0,
  nextPage: 0,
  totalPage: 0,
  current: 0,
};

const BoardComponent = () => {
  const { t, i18n } = useTranslation();

  const [serverData, setServerData] = useState(initState);

  //for FetchingModal
  const [fetching, setFetching] = useState(false);

  const boardDiv = "ko";

  const { page, size, div, refresh, moveToList, moveToRead } = useCustomMove();

  useEffect(() => {
    setFetching(true);

    getList({ div, page, size })
      .then((response) => {
        setServerData(response.data);
        setFetching(false);
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
        setFetching(false);
      });
  }, [div, page, size, refresh]);

  return (
    <>
      <Helmet>
        <title>{t("html-header-support-title")}</title>
        <meta
          name="description"
          content={t("html-header-support-meta-description")}
        />
        <meta
          property="og:title"
          content={t("html-header-support-meta-title")}
        />
        <meta
          property="og:description"
          content={t("html-header-support-meta-description")}
        />
      </Helmet>
      <ul className="nt-list">
        {serverData.dtoList.length > 0 &&
          serverData.dtoList.map((board) => (
            <BoardRowComponent key={board.boardIdx} data={board} />
          ))}
      </ul>
    </>
  );
};

export default BoardComponent;
