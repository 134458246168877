import { Link, useParams } from "react-router-dom";
import RankComponent from "../../components/rank/RankComponent";
import BasicLayout from "../../layout/BasicLayout";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const RankPage = () => {
  const { t, i18n } = useTranslation();

  const { rp } = useParams();

  return (
    <BasicLayout pageClassName="ranking bg-house">
      <div className="content ranking-content">
        <div className="list-st rk-list">
          <ul className="tabs">
            <li className={rp === "all" ? "on" : ""}>
              <Link to="/rank/all">{t("rank-tab-all")}</Link>
            </li>
            <li className={rp === "month" ? "on" : ""}>
              <Link to="/rank/month">{t("rank-tab-month")}</Link>
            </li>
            <li className={rp === "week" ? "on" : ""}>
              <Link to="/rank/week">{t("rank-tab-week")}</Link>
            </li>
            <li className={rp === "today" ? "on" : ""}>
              <Link to="/rank/today">{t("rank-tab-today")}</Link>
            </li>
          </ul>

          <RankComponent rp={rp} />
        </div>
      </div>
    </BasicLayout>
  );
};

export default RankPage;
