import React from "react";
import BasicLayout from "../../layout/BasicLayout";
import ProductListComponent from "../../components/store/ProductListComponent";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const ProductListPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <BasicLayout pageClassName="store">
      <div className="content store-content">
        <div className="front">
          <div className="tit">
            <div>
              <h2>{t("product-tit")}</h2>
              <p>{t("product-desc")}</p>
            </div>
          </div>
        </div>

        <ProductListComponent />
      </div>
    </BasicLayout>
  );
};

export default ProductListPage;
