import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";
import jwtAxios from "../util/jwtUtil";

const host = `${API_SERVER_HOST}/api/product`;

// jwt 토큰 인증 필요
// export const getList = async (params) => {
//   const { nation, pridx } = params;

//   try {
//     const res = await jwtAxios.get(`${host}/list`, {
//       params: { nation: nation, pridx: pridx },
//     });
//     return res;
//   } catch (error) {
//     // 에러를 그대로 던짐 (jwtUtils 에서 받은 에러 그대로 던진다)
//     console.log("^^ product getList - error : ", error);
//     throw error; // ex) 'ERROR_ACCESSDENIED'
//   }
// };

/**
 * jwt 토큰 인증하지 않음
 */
export const getList = async (params) => {
  const { nation, pridx } = params;

  try {
    const res = await axios.get(`${host}/list`, {
      params: { nation: nation, pridx: pridx },
      withCredentials: true, // 쿠키 포함
    });
    return res;
  } catch (error) {
    // 에러를 그대로 던짐 (jwtUtils 에서 받은 에러 그대로 던진다)
    console.log("^^ product getList - error : ", error);
    throw error; // ex) 'ERROR_ACCESSDENIED'
  }
};

/**
 * 게임 리스트
 */
export const getGameList = async (params) => {
  const { nation, pridx, memberId } = params;

  try {
    const res = await axios.get(`${host}/game-list`, {
      params: { nation: nation, pridx: pridx, memberId: memberId },
      withCredentials: true,
    });
    return res;
  } catch (error) {
    console.log("^^product getGameList - error : ", error);
    throw error;
  }
};
