import { API_SERVER_HOST } from "./apiConfig";
import jwtAxios from "../util/jwtUtil";
import axios from "axios";

const host = `${API_SERVER_HOST}/api/game`;

/**
 * 게임 설명
 * return : String[][]
 */
export const getPeriodKingdom = async (params) => {
  //console.log("^^getPeriodKingdom : ", params);
  const { pridx, memberId } = params;
  try {
    const res = await jwtAxios.get(`${host}/pkdesc`, {
      params: { periodIdx: pridx, memberId: memberId },
      withCredentials: true, // 쿠키 포함
    });
    return res;
  } catch (err) {
    //console.log("^^getPeriodKingdom-err: ", err);
    throw err.errorMessage || new Error("Unknown error occurred!!");
  }
};

/**
 * 게임 정보 가져오기 (시대,왕국,게임콘텐츠)
 *   !!주의 - 쿼리 파라미터형식으로 파라미터 요청
 */
export const getGameAll = async (params) => {
  console.log("^^game all : ", params);
  const { pridx, memberId } = params;

  const res = await jwtAxios.get(`${host}/index`, {
    params: { periodIdx: pridx, memberId: memberId },
    withCredentials: true, // 쿠키를 포함하도록 설정
  });
  return res;
};

/**
 * 게임 설정
 * !! 주의 파라미터를 JSON 본문으로 보낸다.
 *    본문 (dto)    --> jwtAxios.post(`${host}/setGame`, params, {}
 *    쿼리 파라미터  --> wtAxios.post(`${host}/setGame`, {params: {...}, {}
 * !! Axios 응답의 res.data와 res.status만 선택적으로 저장하여 직렬화 문제를 해결
 */
export const postSetGame = async (params) => {
  console.log("^^postSetGame-params:", params);
  const res = await jwtAxios.post(`${host}/setGame`, params, {
    withCredentials: true,
  });
  return {
    data: res.data, // 직렬화 가능한 데이터만 반환
    status: res.status, // 필요한 경우 상태 코드만 반환
  };
};

export const postSaveGame = async (params) => {
  console.log("^^postSaveGame : ", params);

  const header = { headers: { "Content-Type": "application/json" } };

  try {
    const res = await jwtAxios.post(`${host}/save`, params, header);
    return res.data;
  } catch (err) {
    console.log("^^postSaveGame-err: ", err);
    throw err.errorMessage || new Error("Unknown error occurred!!");
  }
};

/**
 * 랭킹 가져오기
 */
export const getRank = async (params) => {
  console.log("^^getRank : ", params);
  const { rp, memberId } = params; // rp: 타입(all, month, week, today)

  try {
    const res = await axios.get(`${host}/rank`, {
      params: { t: rp, memberId: memberId },
      withCredentials: true, // 쿠키 포함
    });
    return res.data;
  } catch (err) {
    console.log("^^getRank-err: ", err);
    throw err.errorMessage || new Error("Unknown error occurred!!");
  }
};
