import React from "react";
import LoginComponent from "../../components/member/LoginComponent";
import BasicLayout from "../../layout/BasicLayout";

import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const LoginPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <BasicLayout pageClassName="login bg-map">
      <div className="login-content">
        <h2 className="tit">{t("login-html-head")}</h2>

        <LoginComponent />
      </div>
      <p className="inquiry-txt">To contact : {process.env.REACT_APP_EMAIL}</p>
    </BasicLayout>
  );
};

export default LoginPage;
