import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCookie, setCookie } from "../util/cookieUtil";
import axios from "axios";
import { API_SERVER_HOST } from "../../src/api/apiConfig";

const BasicMenu = () => {
  // 지금부터 뭐가 변하는 것은 저에게 알려주세요.
  // state 는 어플리케이션의 state 다. (컴포넌트 state 아님)
  // loginSlice 부분이 변경되면 여기서 통제를 받게된다.
  // 어느 곳에서든 아래 코드를 사용하면 로그인 데이터 바뀌는 것을 전부 알 수 가 있다.
  const loginState = useSelector((state) => state.loginSlice);

  //console.log("-----BasicMenu loginState :", loginState);

  const lang = getCookie("lang") || "en";

  // 국가 선택 상태
  const [selectedFlag, setSelectedFlag] = useState(
    "/img/flag_" + lang + ".png"
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // 드롭다운 토글 함수
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
    console.log("^^isDropdownOpen : ", isDropdownOpen);
  };

  // 언어 변경 함수
  const changeLanguage = async (lang) => {
    console.log("^^lang: ", lang);

    setCookie("lang", lang, 365);

    // api server(lang)
    const host = `${API_SERVER_HOST}`;
    await axios.post(
      `${host}/api/lang/changeLang`,
      { lang: lang },
      {
        withCredentials: true,
      }
    );

    if (lang === "en") {
      setSelectedFlag("/img/flag_en.png");
    } else if (lang === "ko") {
      setSelectedFlag("/img/flag_ko.png");
    }
    window.location.href = "/main/game-list";
  };

  return (
    <div className="header">
      <ul className="gnb">
        <li>
          <Link to="/store/product">
            <em className="icon"></em>
            <span>상점</span>
          </Link>
        </li>
        <li>
          <Link to="/rank/all">
            <em className="icon"></em>
            <span>랭킹</span>
          </Link>
        </li>
        <li>
          <Link to="/main/game-list">
            <em className="icon"></em>
            <span>홈</span>
          </Link>
        </li>
        <li>
          <Link to="/support/notice">
            <em className="icon">
              <i className="alarm"></i>
            </em>
            <span>알림</span>
          </Link>
        </li>
        <li>
          <Link to="/setting/default">
            <em className="icon"></em>
            <span>설정</span>
          </Link>
        </li>
      </ul>

      <div className="etc-language-selector">
        <div className="etc-language-dropdown" onClick={toggleDropdown}>
          <img src={selectedFlag} alt="Flag" id="selected-flag" width="25px" />
          {isDropdownOpen && (
            <div className="etc-dropdown-content">
              <img
                src="/img/flag_en.png"
                alt="English Flag"
                width="25px"
                onClick={() => changeLanguage("en")}
              />

              <img
                src="/img/flag_ko.png"
                alt="Korean Flag"
                width="25px"
                onClick={() => changeLanguage("ko")}
              />
              <img
                src="/img/flag_es.png"
                alt="España Flag"
                width="25px"
                onClick={() => changeLanguage("es")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicMenu;
