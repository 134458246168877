import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginPost } from "../../api/memberApi";
import { getCookie, removeCookie, setCookie } from "../../util/cookieUtil";

// initState 은 로그인후 아래 데이터로 대체된다.
// 초기값은 memberId 만 있다.
// 로그인이 완료되면 member 정보로 대체된다.(memberIdx, memberId, email, passwd, nickname, memberType, social, refreshToken, tokenExpirationTime, roleNames, accessToken, refreshToken)
// const initState = {
//  memberIdx: 1,
//  memberId: user9@aaa.com,
// 	email: "user9@aaa.com",
// 	passwd: "1111",
// 	nickname: "USER9",
//  memberType: memberType,
// 	social: false,
// 	refreshToken: "aaaa",
// 	tokenExpirationTime: "aaaa",
// 	roleNames: ['USER', 'MANAGER', 'ADMIN'],
//  dto 에 추가 == > claims.put("accessToken", accessToken); claims.put("refreshToken", refreshToken);
// 	accessToken: "xxxx",
// 	refreshToken: "xxxx",
// }

const initState = {
  memberId: "",
};

// 쿠키로 저장된 로그인 결과는 어플리케이션의 실행시 사용되어야 한다.
const loadMemberCookie = () => {
  // 쿠키에서 로그인 정보 로딩
  const memberInfo = getCookie("member");
  // 닉네임 처리
  if (memberInfo && memberInfo.nickname) {
    memberInfo.nickname = decodeURIComponent(memberInfo.nickname); // decodeURIComponent : 인코딩->디코딩 (일반문자열은 그대로 출력)
  }
  //console.log("^^loadMemberCookie : ", memberInfo);
  return memberInfo;
};

// ^^리덕스 툴킷 사용 (createAsyncThunk)
// createAsyncThunk 은 비동기 호출 함수를 만든다.
// export 해서 다른 컴포넌트들에서 함수명을 호출해서 사용할 수 있도록 한다.
// reducer 와 action 을 분리하지 말고 slice 라는 파일에 합쳐서 관리해라
// createAsyncThunk 사용하면 action, store 분리할 필요없이 하나로 합칠수 있다.
// createAsyncThunk 두개의 파라미터 필요 (String 타입 action, promise 타입 콜백함수)
// 콜백함수는 두개의 파라미터를 받는다.(arg:조회시 필요한값들, thunkAPI:API 호출시 필요함 값들 제공)
// thunkAPI 의 사용은 옵션이다.
// createAsyncThunk 는 promise 를 리턴해야 한다 > await 를 사용하면 promise 를 리턴 받을 수 있다.
export const loginPostAsync = createAsyncThunk(
  "loginPostAsync",
  async (param, thunkAPI) => {
    // 단순하게 (param) 만 사용해도 된다.
    return loginPost(param, thunkAPI);
  }
);

// createSlice : 초기상태와 각 action 에 대한 reducer 함수를 자동으로 생성해준다.
// 리듀서가 전달받은 데이터를 확인.
const loginSlice = createSlice({
  name: "LoginSlice", // 슬라이스의 이름을 나타내는 문자열

  // 슬라이스의 초기 상태를 나타내는 객체(loginState.email 처럼 사용됨)
  initialState: loadMemberCookie() || initState, // 쿠키가 없다면 초기값 사용

  // reducers ==> reducer 함수를 정의하는 객체
  // 각 프로퍼티는 액션 타입을 나타내며, 해당 액션을 처리하는 reducer 함수를 값으로 가짐.
  // state: 기존 상태, action: 지금 처리하고 싶은 데이터(action.type, action.payload(parameter))
  reducers: {
    login: (state, action) => {
      // 동기적 호출 (createAsyncThunk 를 사용한 loginPostAsync 가 비동기 함수다.)
      // !!!동일한 이름의 액션 생성자 함수가 자동으로 생성됨
      // 액션의 type 은 자동생성(슬라이스명 + 리듀서명) action.type => LoginSlice/login,
      // 웹에서 전송된 파라미터값 ==> action.payload.memberId/passwd => aa/11
      // 결론은 action 의 type 은 자동생성하고, action 의 payload 는 전송된 파라미터다
      console.log("^^ login....." + state + "," + action.type);
      console.log(action.payload);

      // {소셜로그인 회원이 사용, memberId, passwd 으로 구성}
      const payload = action.payload; // action 의 type 과 payload 가 있는데 payload 는 컴포넌트가 전달하는 데이터다. (파라미터값)

      setCookie("member", JSON.stringify(payload), 30); // Test:1일, 운영할때:30일(Refresh Token), 객체->JSON문자열로(stringify) ==> 쿠키값으로 객체를 사용할 수 없기때문.

      // 필요 데이터만 쿠키로 저장, 사용안함(토큰정보는 무조건 포함되어야함), (api 에서 받은 클레임중 필요한 것만 따로 추출)
      // const cookieData = {
      //   memberIdx: payload.memberIdx,
      //   memberId: payload.memberId,
      //   email: payload.email,
      //   nickname: payload.nickname,
      //   memberType: payload.memberType,
      //   photo: payload.photo,
      //   memberState: payload.memberState,
      // };
      // setCookie("member", JSON.stringify(cookieData), 3600); // 30일(Refresh Token), 객체->JSON문자열로(stringify) ==> 쿠키값으로 객체를 사용할 수 없기때문.

      return payload;

      // 새로운 상태
      // return 키워드를 사용하여 새로운 상태 객체를 반환 (반대 : 특정필드만 수정 (state.email=payload.email))
      // return : 상태값 전체가 완전히 대체됨(initState 의 값 하나하나를 업데이트 하는게 아니라 payload 에 어떤 데이터가 있던 그 데이터로 상태(initState) 를 새로 생성함)
      // email : 원래 이메일값을 action.payload (실제 파라미터:loginParam) 에 있는 이메일 값을 사용한다.
      // return {email: payload.email}
    },
    logout: (state, action) => {
      // 로그아웃은 전송되는 파라미터가 없기때문에 action.payload 접근이 안된다.
      console.log("^^ logout....." + state + "," + action.type);

      removeCookie("member"); // 쿠키 삭제

      return { ...initState };
    },
  },

  // extraReducers 는 외부 라이브러리에 의해서 호출되는 케이스, 그러니깐 extra 한 내용들을 정의한다. (API 는 extraReducers 에서 정의한다.)
  // 이제는 createAsyncThunk 를 이용하면 직접적으로 호출하지 않아도 알아서 상황에 맞춰서 reducer 를 호출하는 함수를 만들고있다.
  // ** loginPostAsync 함수는 promise 를 리턴을 하기만 하면 이 promise 의 상태에 따라서 알아서 케이스를 나눠서 처리한다.
  //  로그인 성공한 경우에는 로그인 정보를 쿠키에 저장하고, 실패한 경우에는 에러를 처리
  // createAsyncThunk 는 extraReducers 사용하게 된다.
  extraReducers: (builder) => {
    // 위에서 createAsyncThunk 를 이용해 만든 비동기 호출 함수를 사용한다. => loginPostAsync
    builder
      .addCase(loginPostAsync.fulfilled, (state, action) => {
        console.log("--fulfilled ↓↓--"); // API 의 JSON 데이터를 가져올 수 있다.
        console.log(action.payload);

        // ** 위 비동기 처리 (createAsyncThunk) 가 끝나면 action.payload 라는 약속된 이름으로 주입된다.
        // return 하면 초기 상태값을 응답데이터(payload) 완전히 덮어쓴다. (전체 상태값 모두)
        // return 키워드 때문에 initState 초기값이 완전 대체된다. (API 에서 반환된 데이타/형식 그대로 완전 대체됨)
        //  ex) const initState = {email: '', error: ''}  ==> {email: "user9@aaa.com", nickname: "USER9", pw: "1111", refreshToken: "aaaa", roleNames: ['USER', 'MANAGER', 'ADMIN'], social: false}};
        const payload = action.payload;

        //닉네임 한글 처리
        if (payload.nickname) {
          payload.nickname = encodeURIComponent(payload.nickname);
        }

        // 정상적인 로그인시에만 저장
        if (!payload.error) {
          console.log("-- 정상 로그인 ---");
          // JSON.stringify(payload) 사용시 : payload = {email: 'a@a.com', error: ''}  ==>  {"email":"a@a.com", error:""}
          setCookie("member", JSON.stringify(payload), 30); // 쿠키 유효기간 1일
        }
        return payload;
      })
      .addCase(loginPostAsync.pending, (state, action) => {
        console.log("--pending--");
      })
      .addCase(loginPostAsync.rejected, (state, action) => {
        console.log("--rejected ↓↓--");
        // 초기값의 특정 필드 값만 업데이트한다. (return 을 사용하면 전체 모두 완전 덮어쓴다.)
        // 다른 컴포넌트에서 상태값 error 을 사용하면 된다.
        state.error = action.payload;
        console.log(action);
        //console.log("----------------");
      });
  },
});

// login 과 logout 함수를 외부에서 사용할수 있도록 한다.
// login, logout 액션 크리에이터로 함수로 만든다.
// dispatch 의 액션을 자동으로 변환/만들어준다 ==>  { type: 'login', payload: '파라미터값' }
export const { login, logout } = loginSlice.actions;

// 이 Slice 의 리듀서를 기본 내보내기로 설정.
// 이 리듀서는 Store 에 추가된다. Store 에 리듀서를 통합할 때 사용.(store.js 에서 사용됨)
export default loginSlice.reducer;
