import React, { useCallback, useEffect, useRef, useState } from "react";
import useCustomGameManage from "../../hooks/useCustomGameManage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { validateData } from "../../util/validate";
import { setGameControl } from "../../redux/slices/gameControlSlice";
import useCustomNative from "../../hooks/useCustomNative";

const ChoiceComponent = ({ data, typeItRef }) => {
  const [ca, setCa] = useState(null);
  const [cl, setCl] = useState(null);
  const [gcIdx, setGcIdx] = useState(null);
  const [optIdx, setOptIdx] = useState(null);

  const { nativeGame } = useCustomNative();

  const dispatch = useDispatch();

  const totalCount = data.length;

  const gameCount = useSelector((state) => state.gameControlSlice.gameCount);
  const optionCount = useSelector(
    (state) => state.gameControlSlice.optionCount
  );

  const { handleGameCallback } = useCustomGameManage(typeItRef);

  const correctListRefs = useRef([]);

  useEffect(() => {
    initChoice(); // 초기화 (시작시간)

    setCa(data[gameCount].correctAnswer);
    setCl(data[gameCount].correctList.split(","));
    setGcIdx(data[gameCount].gameContentIdx);
    setOptIdx(data[gameCount].gameOptions[optionCount].gameOptionIdx);

    correctListRefs.current.forEach((ref) => {
      if (ref) ref.classList.remove("o", "x");
    });
  }, [gameCount]);

  // 1. 정답 체크
  const handleChoice = (e, index, value, ca) => {
    e.preventDefault();

    const isCorrect = validateData(value, ca);

    if (!isCorrect) {
      //console.log("^^App-vibrate()");
      nativeGame({ type: "vibrate", value1: "Y", value2: "" }); // 오답시 진동(RN)
    } else {
      //nativeGame({ type: "sound_typing", value1: "N", value2: "" }); // 사운드 off(RN)
    }

    // console.log(
    //   "^^handleChoice-isCorrect:",
    //   isCorrect.toString(),
    //   "#",
    //   value,
    //   "#",
    //   ca,
    //   "#",
    //   typeItRef
    // );

    correctListRefs.current.forEach((ref) => {
      if (ref) ref.classList.remove("o", "x");
    });

    const _btnClass = isCorrect ? "o" : "x";
    correctListRefs.current.forEach((ref) => ref.classList.remove("o", "x"));
    if (correctListRefs.current[index]) {
      correctListRefs.current[index].classList.add(_btnClass);
    }

    const _gcIdx = data[gameCount].gameContentIdx; // 상태값이 바로 반영이 않되어서 값을 직접 사용
    const _optIdx = data[gameCount].gameOptions[optionCount].gameOptionIdx; // 상태값이 바로 반영이 않되어서 값을 직접 사용

    // 게임이 변경되지 않으면 초기 상태값이 유지가 된다.
    // ChoiceComponent 에서는 optionCount 가 계속 0 이다.
    handleGameCallback({
      value,
      ca,
      gameType: data[gameCount].gameType,
      gcIdx: _gcIdx,
      optIdx: _optIdx,
      optCount: data[gameCount].gameOptions.length,
      totalCount,
      typeItRef,
    });
  };

  const initChoice = () => {
    //console.log("^^initChoice : ", gameCount, "#", optionCount);
    if (optionCount === 0) {
      dispatch(
        setGameControl({
          gcAtStart: Date.now(), // 매 게임 시작 시간
        })
      );
    }
  };

  return (
    <div className="answer-box">
      {cl != null &&
        cl.map((king, index) => (
          <button
            key={index}
            ref={(el) => (correctListRefs.current[index] = el)} //  각 버튼을 배열에 저장
            onClick={(e) => handleChoice(e, index, king, ca)}
            style={{
              fontSize:
                king.length > 18
                  ? "13px"
                  : king.length > 12
                  ? "14px"
                  : "inherit",
            }} // 글자 수에 따른 폰트 크기 설정
          >
            {king}
          </button>
        ))}
    </div>
  );
};

export default ChoiceComponent;
