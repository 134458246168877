import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { modifyMember } from "../../api/myApi";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useModal } from "../common/ModalContext";
import useCustomMember from "../../hooks/useCustomMember";
import { useNavigate } from "react-router-dom";
import { API_SERVER_HOST } from "../../api/apiConfig";
import withLoginCheck from "../../hoc/withLoginCheck";
import { getCookie } from "../../util/cookieUtil";
import { type } from "@testing-library/user-event/dist/type";
import { outPost } from "../../api/myApi";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

const host = API_SERVER_HOST;

const initState = {
  member: {},
  status: "",
  error: null,
};

const ProfileComponent = () => {
  const { t, i18n } = useTranslation();

  //console.log("^^ProfileComponent");
  const [member, setMember] = useState(initState.member); // 수정할 회원정보

  const [result, setResult] = useState(null); // 처리 결과 상태

  const [error, setError] = useState("");

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { openModal } = useModal();

  const { isLogin, doLogout, moveToLogin, loginState, refreshLoginState } =
    useCustomLogin();

  const { refreshMyInfo, resetMyInfo } = useCustomMember();

  //const myInfo = useSelector((state) => state.memberSlice);
  const memberState = useSelector((state) => state.memberSlice);

  if (!isLogin) {
    resetMyInfo(); // 상태 초기화
    moveToLogin();
  }

  // useEffect(() => {
  //   refreshLoginState();
  // }, [refreshLoginState]);

  useEffect(() => {
    if (memberState.status === "idle") {
      const param = { memberId: loginState.memberId };
      refreshMyInfo(param);
    }
  }, [refreshMyInfo, memberState.status, loginState.memberId]);

  useEffect(() => {
    if (memberState.member) {
      setMember(memberState.member);
    }
  }, [memberState.member]);

  // useEffect(() => {
  //   if (myInfo) {
  //     console.log("aaaa2222");
  //     setMember({ ...myInfo }); // myInfo.member로 변경
  //   }
  // }, [myInfo]);

  useEffect(() => {
    // member.photo가 업데이트된 후에 수행할 작업
    if (member.photo) {
      let imgurl = host + "/api/file/member/" + member.photo;
      //console.log("imgurl : ", imgurl);
      setImageSrc(imgurl);
    } else {
      setImageSrc("/img/00-icon-profile.png");
    }
  }, [member.photo]);

  const handleOpenConfirmModal = (e) => {
    e.preventDefault(); // button submit 동작 취소

    //console.log("^^handleClickModify-member : ", member);

    const fileInput = document.getElementById("u-img");
    const file = fileInput.files[0]; // 첨부된 첫 번째 파일

    if (file) {
      // 이미지 파일인지 확인
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"]; // 허용할 이미지 MIME 타입
      if (!allowedTypes.includes(file.type)) {
        setError(t("my-profile-msg-file"));
        return false;
      }
    }

    if (member.nickname === "") {
      setError(t("my-profile-msg-nickname"));
      return false;
    }

    setError("");
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = (e) => {
    setIsConfirmModalOpen(false);
  };

  const handleMemberOut = async () => {
    if (!getCookie("member")) {
      return;
    }

    openModal({
      type: "ADVANCED",
      title: t("my-profile-modal-out-tit"),
      content: t("my-profile-modal-out-desc1"),
      content2: t("my-profile-modal-out-desc2"),
      onConfirmText: t("my-profile-modal-out-btn-ok"),
      onCloseText: t("product-script-no"),
      onConfirm: () => {
        outPost(outData).then((response) => {
          //console.log("resposne.data.result : ", response.data.result);
          response.data.result > 0 && doLogout();
          navigate({ pathname: "/member/login" }, { replace: true });
        });
      },
      onClose: () => null,
    });
  };

  const outData = {
    memberId: loginState.memberId,
    memberIdx: loginState.memberIdx,
  };

  const [imageSrc, setImageSrc] = useState("/img/00-icon-profile.png"); // 기본 이미지

  const uploadRef = useRef(); // <input ref={uploadRef} type=file> 에 사용

  /** 정보 수정 이벤트 */
  const handleChange = (e) => {
    // console.log("^^member : ", member);
    // console.log("e.target.name : ", e.target.name);

    // 이 코드는 직접 변이는 예측할 수 없는 동작 및 렌더링 문제
    // member[e.target.name] = e.target.value;
    // setMember({ ...member }); // 입력 내용이 변경될 때 마다 새로운 member 객체 생성(객체 수정X, 생성O)

    // 아래 코드로 사용하자.
    const { name, value } = e.target;
    setMember((prevMember) => ({
      ...prevMember,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    console.log("e.target.files : ", e.target.files);
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError(t("my-profile-msg-file"));
        return;
      }

      setError(""); // 이전 에러 초기화
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("reader.result : ", reader.result);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickModify = () => {
    // file 태그 접근
    const files = uploadRef.current.files;

    const formData = new FormData();
    formData.append("memberId", member.memberId);
    formData.append("memberIdx", member.memberIdx);
    formData.append("nickname", member.nickname);

    // 새로 선택한 첨부파일 추가
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    // 데이터 확인
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    modifyMember(formData)
      .then((result) => {
        setIsConfirmModalOpen(false);
        setResult("Modified");
        closeModal();
      })
      .catch((err) => {
        alert(
          "handleClickModify-err : " + (err || "An unknown error occurred")
        );
      });
  };

  const navigate = useNavigate();
  const closeModal = () => {
    setResult(null);
    //moveToLogin();
    navigate(0);
  };

  const modalConfirm = () => {
    openModal({
      type: "ADVANCED",
      title: t("my-profile-modal-tit"),
      content: t("my-profile-modal-desc"),
      onConfirmText: t("common-yes"),
      onCloseText: t("common-no"),
      onConfirm: () => handleClickModify(),
      onClose: () => handleCloseConfirmModal(),
    });
  };

  useEffect(() => {
    if (isConfirmModalOpen) {
      modalConfirm();
    }
  }, [isConfirmModalOpen]);

  //console.log("^^member : ", member);

  return (
    <>
      <Helmet>
        <title>{t("html-header-profile-title")}</title>
        <meta
          name="description"
          content={t("html-header-profile-meta-description")}
        />
        <meta
          property="og:title"
          content={t("html-header-profile-meta-title")}
        />
        <meta
          property="og:description"
          content={t("html-header-profile-meta-description")}
        />
      </Helmet>
      <div className="user-info roll">
        <div className="inner">
          <div className="user-photo">
            <label htmlFor="u-img">
              <div className="photo-box">
                <img src={imageSrc} alt="" />
              </div>
              <em className="file-btn"></em>
            </label>
            <input
              ref={uploadRef}
              type={"file"}
              accept="image/*"
              name="photo"
              id="u-img"
              onChange={handleImageChange}
            />
          </div>
          <div className="user-name">
            <input
              type={"text"}
              name="nickname"
              value={member.nickname || ""}
              id="u-nickname"
              placeholder={t("my-profile-msg-nickname1")}
              onChange={handleChange}
            />
            {/*<span className="warning">중복된 닉네임입니다.</span> */}
          </div>

          <div className="ex">
            <p>{t("my-profile-msg-nickname2")}</p>
            {error ? `<p>${error}</p>` : ""}
          </div>
          <button
            type="submit"
            className="ok-btn"
            onClick={handleOpenConfirmModal}
          >
            {t("common-change")}
          </button>
        </div>
        <div className="profile-out">
          <div className="profile-out desc">
            <span>{t("my-profile-txt-out")} 👉 </span>
            <button type="button" onClick={handleMemberOut}>
              {t("my-profile-btn-out")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withLoginCheck(ProfileComponent);
