import { Link, Outlet, useLocation } from "react-router-dom";
import BasicLayout from "../../layout/BasicLayout";
import useCustomLogin from "../../hooks/useCustomLogin";
import ProfileComponent from "../../components/my/ProfileComponent";
import { useTranslation } from "react-i18next";
import "../../i18n"; // i18n 설정 불러오기

/**
 * /my 내의 경로에 대한 레이아웃 역할
 */
const IndexPage = () => {
  const { t, i18n } = useTranslation();

  //   const location = useLocation();
  //   const pageName = location.pathname.substring(
  //     location.pathname.lastIndexOf("/") + 1
  //   );

  const { isLogin, moveToLoginReturn } = useCustomLogin();

  if (!isLogin) {
    return moveToLoginReturn();
  }

  return (
    <BasicLayout pageClassName="profile bg-map">
      <div className="content profile-content">
        <div className="list-st rc-list">
          <ul className="tabs mypage-tabs">
            <li className="on">
              <Link to="../profile">{t("my-tab-profile")}</Link>
            </li>
            <li>
              <Link to="../record">{t("my-tab-game")}</Link>
            </li>
            <li>
              <Link to="../history">{t("my-tab-order")}</Link>
            </li>
          </ul>
          <ProfileComponent />
        </div>
      </div>
    </BasicLayout>
  );
};

export default IndexPage;
