import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";

const host = `${API_SERVER_HOST}/api/board`;

export const getList = async (params) => {
  const { div, page, size } = params;

  try {
    const res = await axios.get(`${host}/`, {
      params: { boardDiv: div, page: page, size: size },
      withCredentials: true, // 쿠키 포함
    });
    return res;
  } catch (error) {
    // 에러를 그대로 던짐 (jwtUtils 에서 받은 에러 그대로 던진다)
    console.log("^^ board - error : ", error);
    throw error; // ex)   'ERROR_ACCESSDENIED'
  }
};

export const getOne = async (bidx) => {
  // const res = await axios.get(`${host}/${bidx}`);

  const res = await axios.get(`${host}/${bidx}`, {
    withCredentials: true, // 쿠키 포함
  });
  return res.data;
};
